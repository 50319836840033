<template>
  <div class="health">
    <div class="oItem-t">
      <div class="title">五江口互动</div>
      <div class="date">{{ date }}</div>
    </div>
    <div class="oItem-b">
      <p>上传图片</p>
      <v-upload
        class="oItem-b_upload"
        :imgUrl.sync="form.proposalPic1"
        :activityPicture.sync="form.proposalPic"
        :maxCount="3"
        ref="load"
      ></v-upload>
      <p class="upload_text">
        请上传互动图片，审核通过后即可获得积分啦，且建议单个图片不超过5M。
      </p>
    </div>
    <div class="submit" @click="submit">提交</div>
  </div>
</template>

<script>
import { postInteractionUrl, myInteractionUrl } from "./api.js";
import { statusMps, setStatusMps, setStatusMpsOps } from "./map.js";
import { toRegister, gloabalCount } from "@/utils/common.js";
var moment = require("moment");
export default {
  name: "health",
  data() {
    return {
      date: moment(new Date()).format("YYYY-MM-DD HH:mm"),
      timer: null,
      id: "",
      setStatusMps,
      form: {
        proposalPic: "",
        proposalContent: "",
      },
      returnData: [],
      returnValue: {},
    };
  },
  destroyed() {
    clearTimeout(this.timer);
  },
  created() {
    gloabalCount("", 8, 1);
    this.id = this.$route.query.id;
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    userId() {
      return this.$store.state.userId;
    },
    roomId() {
      return this.$store.state.houseId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
  },
  mounted() {},
  methods: {
    async submit() {
      if (await toRegister(this.$route.path, this.$route.query, "运动打卡")) {
        if (this.$refs.load.status == 3) {
          this.$toast("图片正在上传中");
          return;
        }
        if (!this.form.proposalPic) {
          this.$toast("请上传图片");
          return;
        }
        this.postData();
      }
    },

    change() {},
    postData() {
      let params = {
        tenantId: this.tenantId,
        userId: this.userId,
        screenshotPic: this.form.proposalPic,
        recId: this.id,
      };
      this.$axios.post(`${postInteractionUrl}`, params).then((res) => {
        if (res.code === 200) {
          this.$toast("提交成功");
          this.$router.replace({
            name: "notice",
          });
        } else {
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.health {
  min-height: 100vh;
  background: #f3f4f9;
  .oItem-t,
  .oItem-c,
  .oItem-b {
    background: #fff;
  }
  .oItem-t {
    padding: 32px 32px 16px 32px;
    display: flex;
    justify-content: space-between;
    .title {
      font-size: 36px;
      font-weight: bold;
      color: #1a1c34;
      line-height: 50px;
    }
  }
  .oItem-c {
    padding: 32px;
    ::v-deep .van-field {
      background: #f7f8fa;
    }
  }
  .oItem-b {
    padding: 32px;
    p {
      font-size: 32px;
      font-weight: bold;
      color: #666666;
      line-height: 44px;
    }
    .oItem-b_upload {
      margin: 32px 0 16px;
      ::v-deep .van-uploader__preview {
        border: 2px dashed #eee;
      }
      ::v-deep .van-uploader__preview-image {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        width: 160px !important;
        height: 160px !important;
        box-sizing: border-box;
      }
      ::v-deep .van-uploader__upload {
        margin: 0;
      }
    }
    .upload_text {
      font-size: 24px;
      font-weight: 400;
      color: #96979b;
      line-height: 32px;
    }
  }
  .submit {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 96px;
    background: #178af7;
    // background: #ccc;
    text-align: center;
    font-size: 32px;
    line-height: 96px;
    color: #fff;
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom); /* 兼容 iOS >= 11.2 */
  }
  .active {
    background: #178af7;
  }
}
</style>
<style lang="less">
.wishes {
  .van-radio__label {
    font-size: 30px;
  }
}
</style>
